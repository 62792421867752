<template>
    <div>
        <nf-breadcrumb :breadList='breadList'></nf-breadcrumb>
        <div class='detailContent'>
            <h2>国家南繁管理体系简介</h2>
            <p class='time'>2018.05.16</p>
            <div class='title'>一、国家南繁管理体系</div>
            <div class='img'>
                <img :src="require('@/assets/images/detail.png')" alt="">
            </div>
            <div class='title'>二、国家南繁工作领导小组办公室职责</div>
            <div class='content'>贯彻执行国家南繁管理方针政策、法律法规规章，组织协调南繁基地建设和管理，衔接、沟通、汇报南繁建设项目信息，协调、推动、督促项目建设进展，保障规
    划项目顺利落地。承担国家南繁工作领导小组日常事务，积极协调涉及南繁单位的各类事项，为广大南繁单位提供服务。</div>
        </div>
        
    </div>
</template>
<script>
import nfBreadcrumb from '@/component/breadcrumb/breadcrumb';
export default {
    components: {
        nfBreadcrumb
    },
    data() {
        return {
             breadList: [{
                name: '关于南繁',
                path: '/aboutNanfan'
            }, {
                name: '详情'
            }]
        };
    }
};
</script>
<style lang="less" scoped>
    h2{
        font-size: 30px;
        color: #333333;
        font-weight: bold;
        text-align: center;
    }
    .time{
        color: #5c5c5c;
        font-size: 16px;
        margin:20px 0 20px;
        padding-bottom:38px;
        text-align: center;
        border-bottom:1px solid #d9d9d9;
    }
    .detailContent{
        width:1181px;
        margin:0 auto;
        line-height: 30px;
        font-size: 16px;
    }
    .title{
        color: #333333;
        font-size: 18px;
    }
    .content{
        text-indent: 2em;
        padding-bottom:57px;
    }
    .img{
        text-align: center;
        margin:28px 0;
    }
</style>